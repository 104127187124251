import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Fitur.css"; // Pastikan CSS telah ditetapkan
import KOL from "../asset/KOL.png";
import mentor from "../asset/mentor.png";
import influencer from "../asset/influencer.png";
import affiliate from "../asset/Affiliate.png";
import kelas from "../asset/kelas.png";
import ERP from "../asset/ERP.png";
import LP from "../asset/LP.png";
import Analisa from "../asset/Analisa.png";

// Array untuk kategori
const categories = [
	{ name: "KOL Specialis", image: KOL },
	{ name: "Influencer", image: influencer },
	{ name: "Mentor Bisnis", image: mentor },
	{ name: "Affiliate", image: affiliate },
];

// Array untuk semua produk
const allProducts = [
	{
		name: "Kelas Dasar Bisnis",
		image: kelas,
		description: `Dapatkan materi dasar terkait bisnis yang akan anda jalankan secara gratis.`,
	},
	{
		name: "ERP Management",
		image: ERP,
		description: `Dapatakan ERP program secara gratis.`,
	},
	{
		name: "Landing Page Build",
		image: LP,
		description: `Buat Landingpage brandmu secara gratis.`,
	},
	{
		name: "Analisa Product",
		image: Analisa,
		description: `Tidur lebih nyaman dan nyenyak dengan Baju Tidur wanita kami yang lembut dan breathable. Terbuat dari bahan yang ringan dan halus di kulit, memastikan kenyamanan tidur Anda.`,
	},
];

const SCRIPT_URL =
	"https://cors-anywhere.herokuapp.com/https://script.google.com/macros/s/AKfycbzIBDgdYKD7FSraxixjPPAm0F0Efx0PVo2HiOfalq39S6Pje13U9vKXg9fEHVi7pBCO/exec"; // Ganti dengan URL Script Google Sheets kamu

const App = () => {
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [idMitra, setIdMitra] = useState(""); // Tambahkan state untuk ID Mitra
	const [isSuccess, setIsSuccess] = useState(false); // State untuk notifikasi berhasil

	const closePopup = () => {
		setSelectedProduct(null);
		setIdMitra(""); // Reset ID Mitra saat popup ditutup
		setIsSuccess(false); // Reset notifikasi ketika popup ditutup
	};

	const handleConsultation = async () => {
		const dateNow = new Date().toLocaleDateString("id-ID"); // Mendapatkan tanggal saat ini
		const dataToSend = {
			idMitra: idMitra,
			productName: selectedProduct?.name,
			date: dateNow,
		};

		try {
			await axios.post(SCRIPT_URL, dataToSend, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log("Data berhasil dikirim:", dataToSend);
			setIsSuccess(true); // Tampilkan notifikasi berhasil
		} catch (error) {
			console.error("Error submitting data", error);
		}
	};

	return (
		<>
			{/* Bagian Kategori */}
			<div className="container">
				<h1 className="title">Lini Glow Up Fitur</h1>
				<div className="product-list1">
					{categories.map((category, index) => (
						<div
							className="product-card"
							key={index}
						>
							<a
								href={`Category_${category.name
									.replace(/\s/g, "")
									.toLowerCase()}`}
							>
								<img
									src={category.image}
									alt={`${category.name} Image`}
								/>
								<h3>{category.name}</h3>
							</a>
						</div>
					))}
				</div>
			</div>

			{/* Bagian Semua Produk */}
			<div className="container">
				<h1 className="title">Semua Fitur</h1>
				<div className="product-list1">
					{allProducts.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)}
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup Produk */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>

									{/* Input ID Mitra */}
									<label htmlFor="idMitra">ID Mitra:</label>
									<input
										type="text"
										id="idMitra"
										value={idMitra}
										onChange={(e) => setIdMitra(e.target.value)}
										placeholder="Masukkan ID Mitra"
									/>

									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>

								{/* Tampilkan notifikasi berhasil jika data telah terkirim */}
								{isSuccess && (
									<div className="success-message">Data berhasil dikirim!</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default App;
