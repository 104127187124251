import React, { useState } from "react";

// Sample product data for demonstration purposes
const allProducts = [
	{
		id: 1,
		name: "SUN Pengering Kutek",
		productID: "27710023218",
		date: "2024-09-27",
		store: "Grosir Abadi Sejahtera",
	},
	{
		id: 2,
		name: "SUN Pengering Kutek",
		productID: "27710023218",
		date: "2024-09-27",
		store: "Grosir Abadi Jaya",
	},
	{
		id: 3,
		name: "SUN Pengering Kutek",
		productID: "27710023218",
		date: "2024-09-27",
		store: "Grosir Sinar Jaya",
	},
	// Add more products as needed
];

const stores = [
	{ name: "Grosir Abadi Sejahtera", id: "abadi-sejahtera" },
	{ name: "Grosir Abadi Jaya", id: "abadi-jaya" },
	{ name: "Grosir Sinar Jaya", id: "sinar-jaya" },
	{ name: "Grosir Sri Rejeki", id: "sri-rejeki" },
	{ name: "Grosir Jaya Mas", id: "jaya-mas" },
	// Add more stores as needed
];

function DataProduct() {
	const [selectedStore, setSelectedStore] = useState(stores[0].name); // Default selected store is the first one

	// Filter products based on the selected store
	const filteredProducts = allProducts.filter(
		(product) => product.store === selectedStore
	);

	return (
		<div className="p-6">
			<h1 className="text-2xl font-bold mb-6">Product</h1>

			{/* Store filter buttons */}
			<div className="flex space-x-4 mb-4">
				{stores.map((store) => (
					<button
						key={store.id}
						className={`px-4 py-2 rounded ${
							selectedStore === store.name
								? "bg-green-500 text-white"
								: "bg-orange-400 text-white"
						}`}
						onClick={() => setSelectedStore(store.name)}
					>
						{store.name}
					</button>
				))}
			</div>

			<div className="flex flex-wrap space-x-4">
				{/* Action Buttons for the Selected Store */}
				<div className="mt-6 bg-white p-4 shadow rounded-lg w-72">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>

					{/* Input for updating via Product ID */}
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Informasi Melalui ID Product
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="Masukan ID"
						/>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>

					{/* Input for updating via Index */}
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Informasi Melalui Index
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="Index Awal"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="Index Akhir"
						/>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>
				</div>
				<div className="mt-6 bg-white p-4 shadow rounded-lg w-72">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>

					{/* Actions for updating all products */}
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Informasi Semua Product
						</h4>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>

					{/* Actions for updating out-of-stock products */}
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Semua Product Stok Kosong
						</h4>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>

					{/* Actions for updating product prices */}
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Semua Product Update Harga
						</h4>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>
				</div>
				<div className="mt-6 bg-white p-4 shadow rounded-lg w-72">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>

					{/* Input for updating via Product ID */}
					{/* Input for updating via Index */}
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">Setting Kenaikan Harga</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="10K - 20K"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="21k - 50K"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="51K - 100K"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="101K - 200K"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="201K - 400K"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="400K ++"
						/>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Save
							</button>
						</div>
					</div>
				</div>

				<div className="mt-6 bg-white p-4 shadow rounded-lg w-72">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Setting Update Product Setiap
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="10K - 20K"
						/>

						<div className="mb-4">
							<h5 className="font-medium text-sm mb-2">
								Product yang ingin diperbarui:
							</h5>
							<div className="space-y-2">
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>Stok</span>
								</label>
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>Harga</span>
								</label>
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>Stok dan Harga</span>
								</label>
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>ALL</span>
								</label>
							</div>
						</div>

						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Save
							</button>
						</div>
					</div>

					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Setting Scrape Product Setiap
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="10K - 20K"
						/>

						<div className="mb-4">
							<h5 className="font-medium text-sm mb-2">
								Product yang ingin diperbarui:
							</h5>
							<div className="space-y-2">
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>Stok</span>
								</label>
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>Harga</span>
								</label>
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>Stok dan Harga</span>
								</label>
								<label className="flex items-center">
									<input
										type="checkbox"
										className="mr-2"
									/>
									<span>ALL</span>
								</label>
							</div>
						</div>

						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* Product Table */}
			<div className="overflow-x-auto">
				<table className="table-auto w-full bg-white shadow rounded-lg mt-5">
					<thead>
						<tr>
							<th className="px-4 py-2">No</th>
							<th className="px-4 py-2">Nama Product</th>
							<th className="px-4 py-2">ID Product</th>
							<th className="px-4 py-2">Tanggal Update</th>
							<th className="px-4 py-2">Tanggal Scape</th>
							<th className="px-4 py-2">Action</th>
						</tr>
					</thead>
					<tbody>
						{filteredProducts.map((product, index) => (
							<tr key={product.id}>
								<td className="border px-4 py-2">{index + 1}</td>
								<td className="border px-4 py-2">{product.name}</td>
								<td className="border px-4 py-2">{product.productID}</td>
								<td className="border px-4 py-2">{product.date}</td>
								<td className="border px-4 py-2">{product.date}</td>
								<td className="border px-4 py-2 text-blue-500">
									Detail Product
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default DataProduct;
