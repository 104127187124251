// App.js
import React from "react";
import Navbar from "../components/Navbar.js";
import HeroSection from "../components/HeroSection.js";
import Product from "../components/product.js";
import PartnerLogos from "../components/PartnerLogos.js";
import Features from "../components/Features.js";
import CallToAction from "../components/CallToAction.js";
import Testimonial from "../components/Testimonial.js";
import Footer from "../components/Footer.js";
import "../App.css"; // Import file CSS untuk gaya yang sama

function App() {
	return (
		<div className="App">
			<Navbar />
			<HeroSection />
			<Product />
			<PartnerLogos />
			<CallToAction />
			<Footer />
		</div>
	);
}

export default App;
