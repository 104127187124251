// Import library react-router-dom
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

// Import komponen halaman
import Home from "./Pages/Home.js";
import Product from "./Pages/Product.js";
import Login from "./Pages/Login.js";
import Register from "./Pages/Register.js";
import Allert from "./Pages/Allert.js";
import Fitur from "./Pages/Fitur.js";
import Admin from "./Pages/Admin.js";
import AdminStore from "./Pages/Admin-store.js";
import AdminProduct from "./Pages/Admin-product.js";

function App() {
	return (
		// Bungkus seluruh aplikasi dengan Router
		<Router>
			<div>
				{/* Daftarkan route dalam Routes */}
				<Routes>
					{/* Route untuk Home sebagai default */}
					<Route
						path="/"
						element={<Navigate to="/home" />}
					/>
					<Route
						path="/home"
						element={<Home />}
					/>
					<Route
						path="/product"
						element={<Product />}
					/>
					<Route
						path="/fitur"
						element={<Fitur />}
					/>
					<Route
						path="/login"
						element={<Login />}
					/>
					<Route
						path="/register"
						element={<Register />}
					/>
					<Route
						path="/allert"
						element={<Allert />}
					/>
					<Route
						path="/admin"
						element={<Admin />}
					/>
					<Route
						path="/store-settings"
						element={<AdminStore />}
					/>
					<Route
						path="/data-products"
						element={<AdminProduct />}
					/>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
