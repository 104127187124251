// components/Testimonial.js
import React from "react";
import "./Testimonial.css";

function Testimonial() {
	return (
		<section className="testimonial">
			<div className="container">
				<h2>Testimonial</h2>

				{/* Left-aligned Testimonial */}
				<div className="testimonial-left">
					<blockquote>
						<p>
							Aplikasi ini benar-benar mengubah cara kami mengelola rantai pasok
							dalam industri fashion. Dengan kecepatan dan efisiensi yang tak
							tertandingi, kami dapat melacak setiap tahap pengiriman produk
							secara real-time, memastikan stok selalu tersedia tepat waktu, dan
							meminimalkan keterlambatan. Fitur otomatisasinya sangat membantu
							dalam mengurangi kesalahan manual, dan integrasi yang mulus dengan
							sistem kami mempermudah seluruh proses operasional. Dalam waktu
							singkat, produktivitas kami meningkat drastis, dan kepuasan
							pelanggan pun melonjak. Aplikasi ini adalah solusi ideal untuk
							industri retail fashion yang membutuhkan kecepatan dan akurasi
							tinggi.
						</p>
					</blockquote>
					<div className="author">
						<p>
							<strong>Anang</strong>
						</p>
						<p>owner Fashion Brand</p>
					</div>
				</div>

				{/* Right-aligned Testimonial */}
				<div className="testimonial-right">
					<blockquote>
						<p>
							Aplikasi supply chain ini telah menjadi game changer bagi
							operasional retail fashion kami. Kecepatannya dalam memproses dan
							mengelola inventaris membantu kami merespons permintaan pasar
							dengan lebih cepat. Mulai dari pengadaan bahan hingga distribusi
							ke toko-toko, semuanya berjalan dengan sangat lancar dan
							transparan. Dengan fitur pelacakan yang detail dan antarmuka yang
							intuitif, kami bisa lebih fokus pada pengembangan produk tanpa
							khawatir tentang kendala logistik. Efisiensi waktu dan biaya yang
							kami dapatkan luar biasa, menjadikannya investasi yang sangat
							berharga
						</p>
					</blockquote>
					<div className="author">
						<p>
							<strong>John Chen</strong>
						</p>
						<p>Founder Brand</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Testimonial;
