import React, { useState } from "react";
import StoreCard from "./StoreCard";
import StoreModal from "./StoreModal";

function StoreSettings() {
	const [selectedStore, setSelectedStore] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const stores = [
		{
			id: 1,
			name: "Grosir Abadi Sejahtera",
			cookie: "Active",
			phone: "123-456",
			status: "Active",
			spc_cds: "SPC001",
		},
		{
			id: 2,
			name: "Grosir Jaya Mas",
			cookie: "Cookie Mati",
			phone: "123-457",
			status: "Inactive",
			spc_cds: "SPC002",
		},
		{
			id: 3,
			name: "Grosir Sentosa Abadi",
			cookie: "Cookie Mati",
			phone: "123-458",
			status: "Inactive",
			spc_cds: "SPC003",
		},
		// Add more stores as needed
	];

	const handleCardClick = (store) => {
		setSelectedStore(store);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className="p-6">
			<h1 className="text-2xl font-bold mb-6">Pengaturan Toko</h1>
			<div className="grid grid-cols-3 gap-4">
				{stores.map((store) => (
					<StoreCard
						key={store.id}
						store={store}
						onCardClick={handleCardClick}
					/>
				))}
			</div>

			<StoreModal
				isOpen={isModalOpen}
				onClose={closeModal}
				storeData={selectedStore}
			/>
		</div>
	);
}

export default StoreSettings;
