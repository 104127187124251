// components/HeroSection.js
import React from "react";
import "./HeroSection.css";

function HeroSection() {
	return (
		<header className="hero">
			<div className="hero-text">
				<h1>Supplier Barang Impor</h1>
				<p>
					Sistem Ekspor-Impor Pertama di Indonesia yang Terintegrasi dengan AI
					dan IoT.
				</p>
				<a
					href="/allert"
					className="button"
				>
					Gabung Mitra
				</a>
			</div>
		</header>
	);
}

export default HeroSection;
