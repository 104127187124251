import React from "react";
import { Line } from "react-chartjs-2";
import {
	Chart,
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	Tooltip,
	Legend,
} from "chart.js";

// Register required components
Chart.register(
	LineElement,
	PointElement,
	LinearScale,
	CategoryScale,
	Title,
	Tooltip,
	Legend
);

function SalesGraph() {
	const data = {
		labels: ["10am", "11am", "12am", "01am", "02am", "03am", "04am", "05am"],
		datasets: [
			{
				label: "Sales",
				data: [10, 60, 50, 65, 70, 60, 90, 80],
				fill: true,
				backgroundColor: "rgba(99, 102, 241, 0.2)",
				borderColor: "rgba(99, 102, 241, 1)",
				tension: 0.4,
				pointBackgroundColor: "#6366F1",
				pointRadius: 4, // Increase point size for visibility
				pointHoverRadius: 8,
				borderWidth: 3, // Increase line thickness for better visibility
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false, // Ensures the chart fills the container
		plugins: {
			tooltip: {
				enabled: true,
				backgroundColor: "rgba(0,0,0,0.8)",
				borderRadius: 10,
				padding: 10,
				titleFont: {
					size: 14,
					weight: "bold",
				},
				bodyFont: {
					size: 12,
				},
			},
			legend: {
				display: true,
				position: "top",
				align: "end",
				labels: {
					color: "#4B5563",
					padding: 20,
					font: {
						size: 14,
					},
				},
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					color: "#6B7280",
					font: {
						size: 14,
					},
				},
				grid: {
					color: "rgba(229, 231, 235, 0.5)",
				},
			},
			x: {
				ticks: {
					color: "#6B7280",
					font: {
						size: 14,
					},
				},
				grid: {
					display: false,
				},
			},
		},
	};

	return (
		<div>
			<Line
				data={data}
				options={options}
			/>
		</div>
	);
}

export default SalesGraph;
