import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router

function Sidebar() {
	return (
		<div className="w-64 h-screen bg-gray-100 p-5">
			<h1 className="text-2xl font-bold mb-10">Pinterjualan</h1>
			<ul className="space-y-4">
				{/* Each menu item is now a clickable link */}
				<li className="text-lg font-semibold text-gray-800">
					<Link to="/admin">🏠 Dashboard</Link>
				</li>
				<li className="text-lg text-gray-600">
					<Link to="/store-settings">⚙️ Pengaturan Toko</Link>
				</li>
				<li className="text-lg text-gray-600">
					<Link to="/data-products">📦 Data Product</Link>
				</li>
				<li className="text-lg text-gray-600">
					<Link to="/finance">💰 Keuangan</Link>
				</li>
				<li className="text-lg text-gray-600">
					<Link to="/sales">📊 Penjualan</Link>
				</li>
				<li className="text-lg text-gray-600">
					<Link to="/messages">
						📩 Messages
						<span className="bg-red-500 text-white px-2 py-1 rounded-full text-sm ml-2">
							49
						</span>
					</Link>
				</li>
				<li className="text-lg text-gray-600">
					<Link to="/settings">⚙️ Settings</Link>
				</li>
			</ul>
		</div>
	);
}

export default Sidebar;
