// components/Footer.js
import React from "react";
import "./Footer.css";
import Youtube from "../asset/Youtube.png";
import Twitter from "../asset/twitter.png";
import Tiktok from "../asset/tiktok.png";
import Instagram from "../asset/instagram.png";

function Footer() {
	const sendEmail = () => {
		const feedback = document.getElementById("feedback-input").value;
		const mailtoLink = `mailto:satuliniofficial@gmail.com?subject=Kritik%20dan%20Saran&body=${encodeURIComponent(
			feedback
		)}`;
		window.location.href = mailtoLink;
	};

	return (
		<footer>
			<div className="container">
				<div className="footer-column">
					<p>
						<br />
						Kantor Pusat
						<br />
						Malang, Jawa Timur Indonesia
					</p>
					<p>
						&copy; 2023 satuliniofficial
						<br />
						All rights reserved
					</p>
					<div className="social-icons">
						<a href="https://www.instagram.com/satulini.id/">
							<img
								src={Instagram}
								alt="Instagram"
							/>
						</a>
						<a href="https://www.tiktok.com/@satulini.id">
							<img
								src={Tiktok}
								alt="Tiktok"
							/>
						</a>
						<a href="#">
							<img
								src={Twitter}
								alt="Twitter"
							/>
						</a>
						<a href="https://www.youtube.com/@SatuLini">
							<img
								src={Youtube}
								alt="YouTube"
							/>
						</a>
					</div>
				</div>

				{/* Satulini Section */}
				<div className="footer-column">
					<h4>Satulini</h4>
					<ul>
						<li>
							<a href="#">Product</a>
						</li>
						<li>
							<a href="#">Fitur</a>
						</li>
						<li>
							<a href="#">Berita</a>
						</li>
						<li>
							<a href="#">Harga</a>
						</li>
						<li>
							<a href="#">Testimonial</a>
						</li>
					</ul>
				</div>

				{/* Bantuan Section */}
				<div className="footer-column">
					<h4>Bantuan</h4>
					<ul>
						<li>
							<a href="#">Pusat bantuan</a>
						</li>
						<li>
							<a href="#">Ketentuan Layanan</a>
						</li>
						<li>
							<a href="#">Legal</a>
						</li>
						<li>
							<a href="#">Kebijakan privasi</a>
						</li>
						<li>
							<a href="#">Status</a>
						</li>
					</ul>
				</div>

				{/* Feedback Section */}
				<div className="footer-column">
					<h4 class="flex-start">Kritik dan saran</h4>
					<form id="feedback-form">
						<input
							type="text"
							id="feedback-input"
							placeholder="Masukan kritik singkat"
						/>
						<button
							type="button"
							className="send-btn"
							onClick={sendEmail}
						>
							Send
						</button>
					</form>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
