import React from "react";
import Sidebar from "../components/Admin/Sidebar";
import StoreProduct from "../components/Admin/Admin-Product/Product";

function Admin() {
	return (
		<div className="flex">
			{/* Sidebar */}
			<Sidebar />
			{/* Main Content */}
			<div className="flex-grow p-6">
				<StoreProduct />
			</div>
		</div>
	);
}

export default Admin;
