import React from "react";

function StoreCard({ store, onCardClick }) {
	return (
		<div
			onClick={() => onCardClick(store)}
			className="cursor-pointer bg-white p-4 rounded-lg shadow-lg"
		>
			<h3 className="text-xl font-semibold">{store.name}</h3>
			<p
				className={`text-sm ${
					store.cookie === "Active" ? "text-green-500" : "text-orange-500"
				}`}
			>
				{store.cookie}
			</p>
			{/* Add more content as needed */}
		</div>
	);
}

export default StoreCard;
