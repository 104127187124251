import React from "react";

function ProductSold() {
	return (
		<div>
			<h3 className="text-lg font-semibold mb-4">Product Terjual Terbanyak</h3>
			<div className="flex items-center space-x-4 mb-4">
				<img
					src="nike-shoe-image-url" // Replace with your image URL
					alt="NIKE Shoes Black Pattern"
					className="w-16 h-16 object-cover rounded-lg"
				/>
				<div>
					<p className="font-bold">NIKE Shoes Black Pattern</p>
					<p className="text-sm text-gray-600">Toko Jaya Abadi</p>
				</div>
			</div>
			<div className="flex items-center space-x-4">
				<img
					src="nike-shoe-image-url" // Replace with your image URL
					alt="NIKE Shoes Black Pattern"
					className="w-16 h-16 object-cover rounded-lg"
				/>
				<div>
					<p className="font-bold">NIKE Shoes Black Pattern</p>
					<p className="text-sm text-gray-600">Toko Jaya Abadi</p>
				</div>
			</div>
		</div>
	);
}

export default ProductSold;
