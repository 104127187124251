import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginPage() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const handleLogin = (e) => {
		e.preventDefault();
		console.log("Email:", email, "Password:", password);
		// Handle login logic here
	};

	const styles = {
		wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "100vh",
			backgroundColor: "#f5f5f5",
			padding: "20px",
		},
		container: {
			backgroundColor: "white",
			padding: "40px",
			borderRadius: "12px",
			boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
			width: "100%",
			maxWidth: "400px",
			display: "flex",
			flexDirection: "column", // Ensure column layout
			alignItems: "center",
		},
		header: {
			textAlign: "center",
			marginBottom: "20px",
			fontSize: "26px",
			fontWeight: "bold",
		},
		form: {
			display: "flex",
			flexDirection: "column", // Force form elements into a column
			width: "100%",
		},
		formGroup: {
			display: "flex",
			flexDirection: "column", // Stack label and input vertically
			width: "100%",
			marginBottom: "20px",
		},
		label: {
			marginBottom: "5px",
			fontWeight: "bold",
		},
		input: {
			width: "100%",
			padding: "12px",
			border: "1px solid #ddd",
			borderRadius: "6px",
			fontSize: "16px",
			boxSizing: "border-box",
		},
		button: {
			width: "100%",
			backgroundColor: "#6c63ff",
			color: "white",
			padding: "12px",
			border: "none",
			borderRadius: "6px",
			fontSize: "16px",
			fontWeight: "600",
			cursor: "pointer",
			marginTop: "10px",
			transition: "background-color 0.3s ease",
		},
		buttonHover: {
			backgroundColor: "#574bcc",
		},
		signup: {
			textAlign: "center",
			marginTop: "15px",
			fontSize: "14px",
		},
		signupLink: {
			color: "#6c63ff",
			textDecoration: "none",
			fontWeight: "600",
		},
		backButton: {
			marginTop: "15px",
			width: "100%",
			backgroundColor: "#e0e0e0",
			color: "#333",
			padding: "12px",
			borderRadius: "6px",
			fontSize: "16px",
			cursor: "pointer",
			border: "none",
			transition: "background-color 0.3s ease",
		},
	};

	return (
		<div style={styles.wrapper}>
			<div style={styles.container}>
				<h2 style={styles.header}>Login</h2>
				<form
					onSubmit={handleLogin}
					style={styles.form}
				>
					<div style={styles.formGroup}>
						<label
							htmlFor="email"
							style={styles.label}
						>
							Email
						</label>
						<input
							type="email"
							id="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							style={styles.input}
						/>
					</div>
					<div style={styles.formGroup}>
						<label
							htmlFor="password"
							style={styles.label}
						>
							Password
						</label>
						<input
							type="password"
							id="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							style={styles.input}
						/>
					</div>
					<button
						type="submit"
						style={styles.button}
						onMouseOver={(e) =>
							(e.target.style.backgroundColor =
								styles.buttonHover.backgroundColor)
						}
						onMouseOut={(e) =>
							(e.target.style.backgroundColor = styles.button.backgroundColor)
						}
					>
						Login
					</button>
				</form>
				{/* <div style={styles.signup}>
					<p>
						Don't have an account?{" "}
						<a
							href="/daftar"
							style={styles.signupLink}
						>
							Sign Up
						</a>
					</p>
				</div> */}
				<button
					style={styles.backButton}
					onMouseOver={(e) => (e.target.style.backgroundColor = "#ccc")}
					onMouseOut={(e) => (e.target.style.backgroundColor = "#e0e0e0")}
					onClick={() => navigate(-1)}
				>
					Back
				</button>
			</div>
		</div>
	);
}

export default LoginPage;
