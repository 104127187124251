import React from "react";
import SalesGraph from "./SalesGraph";
import TransactionChart from "./TransactionChart";
import OrdersTable from "./SalesDetail"; // First Card
import ProductSold from "./SalesProduct"; // Second Card

function Dashboard() {
	return (
		<div className="space-y-6">
			{/* Statistics Cards */}
			<div className="grid grid-cols-4 gap-4">
				<div className="bg-white p-4 rounded-lg shadow">
					<p className="text-sm text-gray-600">Pesanan Baru</p>
					<h2 className="text-2xl font-bold">178+</h2>
				</div>
				<div className="bg-white p-4 rounded-lg shadow">
					<p className="text-sm text-gray-600">Pesanan Dikirim</p>
					<h2 className="text-2xl font-bold">20+</h2>
				</div>
				<div className="bg-white p-4 rounded-lg shadow">
					<p className="text-sm text-gray-600">Pesanan Sukses</p>
					<h2 className="text-2xl font-bold">190+</h2>
				</div>
				<div className="bg-white p-4 rounded-lg shadow">
					<p className="text-sm text-gray-600">Total Pesanan</p>
					<h2 className="text-2xl font-bold">12+</h2>
				</div>
			</div>

			{/* Graph and Chart */}
			<div className="grid grid-cols-2 gap-4">
				<div
					className="bg-white p-4 rounded-lg shadow-lg"
					style={{ height: "350px" }}
				>
					<SalesGraph />
				</div>
				<div
					className="bg-white p-4 rounded-lg shadow-lg"
					style={{ height: "350px" }}
				>
					<TransactionChart />
				</div>
			</div>

			{/* Orders Table and Product Sold */}
			<div className="grid grid-cols-2 gap-4">
				<div className="bg-white p-4 rounded-lg shadow">
					<OrdersTable />
				</div>
				<div className="bg-white p-4 rounded-lg shadow">
					<ProductSold />
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
