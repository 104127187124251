import React from "react";

function OrdersTable() {
	return (
		<div>
			<h3 className="text-lg font-semibold mb-4">Riwayat Orderan</h3>
			<table className="table-auto w-full">
				<thead>
					<tr>
						<th className="px-4 py-2">Nomer Pesanan</th>
						<th className="px-4 py-2">Nama Product</th>
						<th className="px-4 py-2">Harga</th>
						<th className="px-4 py-2">Modal</th>
						<th className="px-4 py-2">Untung</th>
						<th className="px-4 py-2">Nama Toko</th>
						<th className="px-4 py-2">Tanggal</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="border px-4 py-2">#876364</td>
						<td className="border px-4 py-2">Camera Lens</td>
						<td className="border px-4 py-2">$178</td>
						<td className="border px-4 py-2">$178</td>
						<td className="border px-4 py-2">$1,46,660</td>
						<td className="border px-4 py-2">Jaya Abadi</td>
						<td className="border px-4 py-2">12-Oct-2023 14:35</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876368</td>
						<td className="border px-4 py-2">Black Sleep Dress</td>
						<td className="border px-4 py-2">$14</td>
						<td className="border px-4 py-2">$14</td>
						<td className="border px-4 py-2">$46,660</td>
						<td className="border px-4 py-2">Jaya Mas</td>
						<td className="border px-4 py-2">11-Oct-2023 11:20</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876412</td>
						<td className="border px-4 py-2">Argan Oil</td>
						<td className="border px-4 py-2">$21</td>
						<td className="border px-4 py-2">$21</td>
						<td className="border px-4 py-2">$3,46,676</td>
						<td className="border px-4 py-2">Abadi Sejahtera</td>
						<td className="border px-4 py-2">10-Oct-2023 16:45</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876621</td>
						<td className="border px-4 py-2">EAU DE Parfum</td>
						<td className="border px-4 py-2">$32</td>
						<td className="border px-4 py-2">$32</td>
						<td className="border px-4 py-2">$3,46,981</td>
						<td className="border px-4 py-2">Sri Rejeki</td>
						<td className="border px-4 py-2">09-Oct-2023 13:50</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876765</td>
						<td className="border px-4 py-2">Wireless Earbuds</td>
						<td className="border px-4 py-2">$79</td>
						<td className="border px-4 py-2">$50</td>
						<td className="border px-4 py-2">$2,30,000</td>
						<td className="border px-4 py-2">Tech Hub</td>
						<td className="border px-4 py-2">08-Oct-2023 09:30</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876877</td>
						<td className="border px-4 py-2">Bluetooth Speaker</td>
						<td className="border px-4 py-2">$120</td>
						<td className="border px-4 py-2">$100</td>
						<td className="border px-4 py-2">$3,00,000</td>
						<td className="border px-4 py-2">Sound Arena</td>
						<td className="border px-4 py-2">07-Oct-2023 15:10</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876920</td>
						<td className="border px-4 py-2">Smart Watch</td>
						<td className="border px-4 py-2">$210</td>
						<td className="border px-4 py-2">$180</td>
						<td className="border px-4 py-2">$4,20,000</td>
						<td className="border px-4 py-2">Gadget Store</td>
						<td className="border px-4 py-2">06-Oct-2023 18:00</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#876971</td>
						<td className="border px-4 py-2">4K TV</td>
						<td className="border px-4 py-2">$550</td>
						<td className="border px-4 py-2">$500</td>
						<td className="border px-4 py-2">$10,00,000</td>
						<td className="border px-4 py-2">Home Vision</td>
						<td className="border px-4 py-2">05-Oct-2023 20:00</td>
					</tr>
					<tr>
						<td className="border px-4 py-2">#877001</td>
						<td className="border px-4 py-2">Gaming Laptop</td>
						<td className="border px-4 py-2">$1,200</td>
						<td className="border px-4 py-2">$1,100</td>
						<td className="border px-4 py-2">$15,00,000</td>
						<td className="border px-4 py-2">Pro Gamers</td>
						<td className="border px-4 py-2">04-Oct-2023 12:30</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default OrdersTable;
