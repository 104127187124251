import React from "react";

function StoreModal({ isOpen, onClose, storeData }) {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
			<div className="bg-white p-6 rounded-lg w-96">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-semibold">Data Toko</h2>
					<button
						onClick={onClose}
						className="text-red-500"
					>
						✖
					</button>
				</div>

				<div className="space-y-4">
					<input
						type="text"
						className="w-full p-2 border rounded"
						value={storeData?.name || ""}
						placeholder="Nama Toko"
						readOnly
					/>
					<input
						type="text"
						className="w-full p-2 border rounded"
						value={storeData?.phone || ""}
						placeholder="Phone"
						readOnly
					/>
					<input
						type="text"
						className="w-full p-2 border rounded"
						value={storeData?.status || ""}
						placeholder="Status"
						readOnly
					/>
					<input
						type="text"
						className="w-full p-2 border rounded"
						value={storeData?.cookie || ""}
						placeholder="Cookie"
						readOnly
					/>
					<input
						type="text"
						className="w-full p-2 border rounded"
						value={storeData?.spc_cds || ""}
						placeholder="SPC_CDS"
						readOnly
					/>

					<div className="flex justify-between">
						<button
							onClick={onClose}
							className="bg-gray-200 px-4 py-2 rounded"
						>
							Close
						</button>
						<button className="bg-blue-500 text-white px-4 py-2 rounded">
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StoreModal;
