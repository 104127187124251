// components/Features.js
import React from "react";
import "./Features.css";
import LiniInsight from "../asset/lini-insight.png";
import LiniManagement from "../asset/lini-management.png";
import LiniGlowup from "../asset/lini-glowup.png";

function Features() {
	return (
		<section className="features">
			<div className="container">
				<h2>Kelola Bisnis Anda dalam Satu Sistem</h2>
				<div className="feature-grid">
					<div className="feature">
						<img
							src={LiniInsight}
							alt="Logo Lini Insight"
							className="feature-logo"
						/>
						<h3>Lini Insight</h3>
						<p>
							Fitur-fitur yang dirancang untuk membantu Anda mengidentifikasi
							dan mengeksplorasi produk potensial yang dapat Anda pasarkan dan
							jual dengan sukses.
						</p>
					</div>
					<div className="feature">
						<img
							src={LiniManagement}
							alt="Logo Lini Management"
							className="feature-logo"
						/>
						<h3>Lini Management</h3>
						<p>
							Perangkat lunak kami mencakup fitur untuk mengelola produk dengan
							mudah, dengan memanfaatkan konsep rantai pasok yang transparan.
						</p>
					</div>
					<div className="feature">
						<img
							src={LiniGlowup}
							alt="Logo Lini Glow Up"
							className="feature-logo"
						/>
						<h3>Lini Glow Up</h3>
						<p>
							Fitur yang membantu meningkatkan upaya pemasaran, mulai dari alat
							terbaru hingga edukasi yang komprehensif.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Features;
