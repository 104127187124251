import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./product1.css";
import Test from "../asset/Images.png";

// Array for all featured products
const featuredProducts = [
	{
		name: "Syltherine",
		image: Test,
		description: "Stylish cafe chair",
		price: "Rp 2.500.000",
		originalPrice: "Rp 3.500.000",
		discount: "-30%",
		tag: null,
	},
	{
		name: "Lolito",
		image: Test,
		description: "Luxury big sofa",
		price: "Rp 7.000.000",
		originalPrice: "Rp 14.000.000",
		discount: "-50%",
		tag: null,
	},
	{
		name: "Respira",
		image: Test,
		description: "Outdoor bar table and stool",
		price: "Rp 500.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
	{
		name: "Grifo",
		image: Test,
		description: "Night lamp",
		price: "Rp 1.500.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
	{
		name: "Muggo",
		image: Test,
		description: "Small mug",
		price: "Rp 150.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
	{
		name: "Pingky",
		image: Test,
		description: "Cute bed set",
		price: "Rp 7.000.000",
		originalPrice: "Rp 14.000.000",
		discount: "-50%",
		tag: null,
	},
	{
		name: "Potty",
		image: Test,
		description: "Minimalist flower pot",
		price: "Rp 500.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
];

const App = () => {
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);

	const closePopup = () => {
		setSelectedProduct(null);
	};

	const handleConsultation = () => {
		navigate("/register");
	};

	return (
		<>
			<div className="container">
				<h1 className="title">Product Unggulan</h1>
				<div className="product-list">
					{featuredProducts.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)}
						>
							{product.discount && (
								<div className="discount">{product.discount}</div>
							)}
							{product.tag && <div className="tag">{product.tag}</div>}
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
							<p className="description">{product.description}</p>
							<div className="price-container">
								<span className="price">{product.price}</span>
								{product.originalPrice && (
									<span className="original-price">
										{product.originalPrice}
									</span>
								)}
							</div>
						</div>
					))}
				</div>

				{/* Popup Product */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>
									<p>
										<strong>Price:</strong> {selectedProduct.price}
									</p>
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default App;
