import React from "react";
import Navbar from "../components/Navbar.js";
import WarningPage from "../components/Warningpage.js";
import Footer from "../components/Footer.js";

function App() {
	return (
		<div>
			<Navbar />
			<WarningPage />
			<Footer />
		</div>
	);
}

export default App;
