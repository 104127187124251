import React from "react";
import { Link } from "react-router-dom";
import "./Warningpage.css";

function WarningPage() {
	return (
		<div className="main-content">
			<div className="warning-container">
				<h2 className="warning-title">Peringatan!</h2>
				<p className="warning-text">
					Halaman ini tersedia untuk mitra saja, silahkan kembali.
				</p>
				<Link
					to="/"
					className="back-btn"
				>
					Kembali
				</Link>
			</div>
		</div>
	);
}

export default WarningPage;
