import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

// Register required components
Chart.register(ArcElement, Tooltip, Legend);

function TransactionChart() {
	const data = {
		labels: ["Sisa Dana", "Dana Tertahan", "Dana Cari"],
		datasets: [
			{
				data: [80, 10, 10],
				backgroundColor: ["#3B82F6", "#FBBF24", "#F87171"],
				borderWidth: 2, // Adjust the border for better visibility
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false, // Ensures the pie chart takes up more space
		plugins: {
			tooltip: {
				enabled: true,
				backgroundColor: "rgba(0,0,0,0.8)",
				borderRadius: 10,
				padding: 10,
				titleFont: {
					size: 14,
					weight: "bold",
				},
				bodyFont: {
					size: 12,
				},
			},
			legend: {
				display: true,
				position: "top",
				labels: {
					color: "#4B5563",
					padding: 20,
					font: {
						size: 14,
					},
				},
			},
		},
		layout: {
			padding: {
				top: 10, // Add a small padding on top
				bottom: 10, // Add a small padding at the bottom
				left: 10, // Add padding on the left
				right: 10, // Add padding on the right
			},
		},
		cutout: "0%", // No cutout, so it stays as a full pie
	};

	return (
		<div style={{ height: "100%" }}>
			<Pie
				data={data}
				options={options}
			/>
		</div>
	);
}

export default TransactionChart;
