import React from "react";
import "./PartnerLogos.css";
import BNI from "../asset/BNI.png";
import BRI from "../asset/BRI.png";
import BankJatim from "../asset/bankjatim.png";
import TNI from "../asset/TNI.png";
import PMI from "../asset/PMI.png";
import WordSkill from "../asset/Wordskill.png";
import JasaTirta from "../asset/JasaTirta1.png";
import erha from "../asset/logo (1).png";
import xixiu from "../asset/images (1).jfif.jpg";
import politeknik from "../asset/Logo_Politeknik_Negeri_Malang.png";
import unisma from "../asset/Logo_UNISMA_Malang-logo1.png";
import msglow from "../asset/ms-glow-beauty-logo.png";
import pegadain from "../asset/Pegadaian_new_logo.png";
import IndonesiaMap from "../asset/Indonesia.png"; // Add your map image here

function PartnerLogos() {
	return (
		<section className="partner-logos">
			<div className="container">
				{/* Add the map above the logos */}

				<h2>Mitra Kami</h2>
				<div className="map-section">
					<img
						src={IndonesiaMap}
						alt="Map of Indonesia"
					/>
				</div>
				<p>Kami telah bekerja sama dengan 100+ mitra di Seluruh Indonesia</p>
				<div className="logos">
					<img
						src={BNI}
						alt="Partner Logo 1"
					/>
					<img
						src={BRI}
						alt="Partner Logo 2"
					/>
					<img
						src={BankJatim}
						alt="Partner Logo 3"
					/>
					<img
						src={TNI}
						alt="Partner Logo 4"
					/>
					<img
						src={PMI}
						alt="Partner Logo 5"
					/>
					<img
						src={WordSkill}
						alt="Partner Logo 6"
					/>
					<img
						src={erha}
						alt="Partner Logo 7"
					/>
					<img
						src={politeknik}
						alt="Partner Logo 8"
					/>
					<img
						src={msglow}
						alt="Partner Logo 9"
					/>
					<img
						src={unisma}
						alt="Partner Logo 10"
					/>
					<img
						src={pegadain}
						alt="Partner Logo 11"
					/>
					<img
						src={xixiu}
						alt="Partner Logo 12"
					/>
				</div>
			</div>
		</section>
	);
}

export default PartnerLogos;
